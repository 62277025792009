import { initializeApp } from "firebase/app";
import {
  getAuth,
  FacebookAuthProvider,
  GoogleAuthProvider,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getStorage } from "firebase/storage";

import { createStorefrontApiClient } from "@shopify/storefront-api-client";

const firebaseConfigBeta = {
  apiKey: "AIzaSyDZaR1Uo7WQTDojlFClLH4idAlSBAaEbV8",
  authDomain: "labme-beta.firebaseapp.com",
  databaseURL: "https://labme-beta.firebaseio.com",
  projectId: "labme-beta",
  storageBucket: "labme-beta.appspot.com",
  messagingSenderId: "440048496206",
  appId: "1:440048496206:web:af3dfd4666772dc79a1d1f",
  measurementId: "G-EPEMBPT7QS",
};

const firebaseConfigProd = {
  apiKey: "AIzaSyDvajHar3Bv6rb-RMS_2OEr5tUmktn-pWQ",
  authDomain: "labme-prod.firebaseapp.com",
  databaseURL: "https://labme-prod.firebaseio.com",
  projectId: "labme-prod",
  storageBucket: "labme-prod.appspot.com",
  messagingSenderId: "1065219065302",
  appId: "1:1065219065302:web:05baa2bdac4520d5759077",
  measurementId: "G-Y65E4H82DT",
};

const firebaseConfig = process.env.REACT_APP_STAGE === 'prod' ? firebaseConfigProd : firebaseConfigBeta;

export const myFirebase = initializeApp(firebaseConfig);

// initialize analytics
const analytics = getAnalytics(myFirebase);
export const firebaseAnalytics = analytics;

export const firebaseAnalyticsEventName = logEvent;

const baseDb = getFirestore(myFirebase);
export const db = baseDb;

const auth = getAuth(myFirebase);
export const firebaseAuth = auth;

const functions = getFunctions(myFirebase, "us-central1");
export const firebaseFunctions = functions;

export const facebookAuthProvider = new FacebookAuthProvider();
export const googleAuthProvider = new GoogleAuthProvider();

const shopifyAccessToken = process.env.REACT_APP_SHOPIFY_ACCESS_TOKEN;
const shopifyDomain = process.env.REACT_APP_SHOPIFY_DOMAIN;

let googlePlacesApi_ = null;
if (process.env.REACT_APP_STAGE === "prod") {
  googlePlacesApi_ = "AIzaSyDvajHar3Bv6rb-RMS_2OEr5tUmktn-pWQ";
} else {
  googlePlacesApi_ = "AIzaSyDZaR1Uo7WQTDojlFClLH4idAlSBAaEbV8";
}

export const googlePlacesApi = googlePlacesApi_;
export const shopifyStoreFrontClient = createStorefrontApiClient({
  storeDomain: shopifyDomain,
  apiVersion: "2023-10",
  publicAccessToken: shopifyAccessToken,
  customFetchApi: fetch,
});

// initialize storage
export const storage = getStorage(myFirebase);