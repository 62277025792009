import React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
 
class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: '' };
  }
 
  handleChange = address => {
    this.setState({ address });
    this.props.onChange({target: {name: "line1", value: address}})
  };
 
  handleSelect = (address, placeId, suggestion) => {
    if(suggestion?.placeId){
      const service = new window.google.maps.places.PlacesService(document.createElement("div"));
      service.getDetails({
        placeId: suggestion.placeId,
        fields: ["address_components"]
      }, details => {
        let postcode = null
        details.address_components.map(entry => {
          if (entry.types?.[0] === "postal_code") {
            postcode = entry.long_name
          }
        })
        this.props.onChange({target: {name: "postal_code", value: postcode}})
      }); 
      let addressFinal = suggestion.terms[suggestion.terms?.length - 5] ? suggestion.terms[suggestion.terms?.length - 5].value + " " : "";
      this.setState({ address: addressFinal + suggestion.terms[suggestion.terms?.length - 4]?.value });
      this.props.onChange({target: {name: "line1", value: addressFinal + suggestion.terms[suggestion.terms?.length - 4]?.value}})
      this.props.onChange({target: {name: "state", value: suggestion.terms[suggestion.terms?.length - 2]?.value}})
      this.props.onChange({target: {name: "city", value: suggestion.terms[suggestion.terms?.length - 3]?.value}})
    }
  };
 
  render() {
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        searchOptions={{
            componentRestrictions: { country: ['us'] },
            types: ['address']
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
               name="line1"
               autoComplete="address-line1"
              {...getInputProps({
                placeholder: 'Search Places ...',
                className: 'location-search-input',
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                  if(suggestion.terms[suggestion.terms?.length - 2]?.value === 'NY'){
                      return null
                  }
                const className = suggestion.active
                  ? 'suggestion-item--active d-flex align-items-center'
                  : 'suggestion-item d-flex align-items-center';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#f9f9f9', cursor: 'pointer', height: 35, fontSize: "0.875rem" }
                  : { backgroundColor: '#ffffff', cursor: 'pointer', height: 35, fontSize: "0.875rem" };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                    key={suggestion.description}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default LocationSearchInput;
