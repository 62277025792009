import React from "react";

import { Routes, Route, Navigate } from "react-router-dom";
import CheckoutV2 from "views/pages/checkout/CheckoutV2";

const App = ({ isAuthenticated, isVerifying }) => {
  if (isVerifying) {
    return <div></div>;
  }

  return (
    <Routes>
      <Route path="/:id" element={<CheckoutV2 />} />
      <Route path="*" element={<Navigate to="/1" replace />} />
    </Routes>
  );
};

export default App;
