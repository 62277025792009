import * as actionTypes from "./actionTypes";
import * as helpers from "../../helpers";
import {
  firebaseFunctions,
  myFirebase,
  shopifyStoreFrontClient,
} from "../../firebase/firebase";
import * as Sentry from "@sentry/react";
import { httpsCallable } from "firebase/functions";

const getProductRequest = () => {
  return {
    type: actionTypes.GET_PRODUCT_LIST_REQUEST,
  };
};

// TODO: need to update later
const getProductSuccess = (data) => {
  return {
    type: actionTypes.GET_PRODUCT_LIST_SUCCESS,
    products: data,
  };
};

const getProductsFailure = (error) => {
  return {
    type: actionTypes.GET_PRODUCT_LIST_FAILURE,
    error: error,
  };
};

/*export const getProducts = (uid) => dispatch => {
    dispatch(getProductsRequest());
    myFirebase.firestore().collection('products')
        .where('active', '==', true)
        .get()
        .then(doc => {
        if (doc.exists) {
            dispatch(getProductesSuccess(doc.data()))
        } else {
            console.log("No data presented for " + uid);
            dispatch(getProductsFailure(null));
        }
    }).catch(error => {
        console.log(error);
        dispatch(getProductsFailure(error.message))
    })
}*/

// Start a subscription with Stripe Checkout
const startSubscriptionCheckoutRequest = () => {
  return {
    type: actionTypes.START_SUBSCRIPTION_CHECKOUT_REQUEST,
  };
};

const startSubscriptionCheckoutSucess = (sessionId) => {
  return {
    type: actionTypes.START_SUBSCRIPTION_CHECKOUT_SUCCESS,
    sessionId: sessionId,
  };
};

const startSubscriptionCheckoutFailure = (error) => {
  return {
    type: actionTypes.START_SUBSCRIPTION_CHECKOUT_FAILURE,
    error: error,
  };
};

export const getProduct = (id) => (dispatch) => {
  dispatch(getProductRequest());
  httpsCallable(
    firebaseFunctions,
    "getShopifyProduct"
  )({
    product_id: id,
  })
    .then((data) => {
      dispatch(getProductSuccess(data.data.product));
    })
    .catch((error) => {
      // Enhancing error with additional context
      const enhancedError = new Error(
        `Error in getProduct with ID ${id}: ${error.message}`
      );
      enhancedError.stack = error.stack; // Preserve original stack trace

      Sentry.captureException(enhancedError);
      dispatch(getProductsFailure(error.message));
    });
};

function encodeGlobalID(type, numericID) {
  return btoa(`gid://shopify/${type}/${numericID}`);
}

async function getProductById(productId) {
  const query = `
  query getProductById($id: ID!) {
    product(id: $id) {
      title
      id
      handle
      variants(first: 1) {
        edges {
          node {
            id
            title
          }
        }
      }
    }
  }
`;
  try {
    const globalProductID = encodeGlobalID("Product", productId);
    const variables = { id: globalProductID };
    const response = await shopifyStoreFrontClient.request(query, {
      variables: variables,
    });
    return response;
  } catch (error) {
    const enhancedError = new Error(
      `Failed to fetch product by ID ${productId}: ${error.message}`
    );
    enhancedError.stack = error.stack;
    Sentry.captureException(enhancedError);
    console.error("Failed to fetch product by ID:", error);
    throw error;
  }
}

export const generateCheckout =
  (id, quantity, user_info) => async (dispatch) => {
    try {
      dispatch(startSubscriptionCheckoutRequest());

      // Call your Firebase function for backend processing
      const [variantsResponse, productData] = await Promise.all([
        getProductById(id),
        httpsCallable(
          firebaseFunctions,
          "getShopifyProduct"
        )({
          product_id: id,
          generate_checkout: true,
          user_info: {
            email: helpers.trimString(user_info.email),
            first_name: helpers.trimString(user_info.first_name),
            last_name: helpers.trimString(user_info.last_name),
            dob: helpers.trimString(user_info.dob),
            gender: helpers.trimString(user_info.gender),
            phone: helpers.trimString(user_info.phone),
            line1: helpers.trimString(user_info.line1),
            line2: helpers.trimString(user_info.line2),
            city: helpers.trimString(user_info.city),
            state: helpers.trimString(user_info.innerState),
            postal_code: helpers.trimString(user_info.postal_code),
          },
        }),
      ]);

      const cartCreateMutation = `
      mutation cartCreate($input: CartInput!) {
        cartCreate(input: $input) {
          cart {
            id
            checkoutUrl
          }
          userErrors {
            field
            message
          }
        }
      }
    `;

      const { data } = await shopifyStoreFrontClient.request(
        cartCreateMutation,
        {
          variables: {
            input: {
              lines: [
                {
                  merchandiseId:
                    variantsResponse.data.product.variants.edges[0].node.id,
                  quantity: quantity,
                },
              ],
              attributes: [
                { key: "email", value: user_info.email },
                { key: "gender", value: user_info.gender },
                { key: "dob", value: user_info.dob },
              ],
              buyerIdentity: {
                email: user_info.email,
                phone: user_info.phone.replace(/\D/g, ""),
                countryCode: "US",
                deliveryAddressPreferences: [
                  {
                    deliveryAddress: {
                      address1: user_info.line1,
                      address2: user_info.line2 || "",
                      city: user_info.city,
                      country: "US",
                      firstName: user_info.first_name,
                      lastName: user_info.last_name,
                      phone: user_info.phone.replace(/\D/g, ""),
                      province: user_info.innerState,
                      zip: user_info.postal_code,
                    },
                  },
                ],
              },
            },
            country: "US",
            language: "EN",
          },
        }
      );

      if (data.cartCreate.userErrors.length > 0) {
        dispatch(
          startSubscriptionCheckoutFailure(
            data.cartCreate.userErrors[0].message
          )
        );
        Sentry.captureException(
          new Error(data.cartCreate.userErrors[0].message)
        );
        return;
      }
      dispatch(
        startSubscriptionCheckoutSucess(data.cartCreate.cart.checkoutUrl)
      );
    } catch (error) {
      const enhancedError = new Error(
        `Error in generateCheckout with ID ${id}: ${error.message}`
      );
      enhancedError.stack = error.stack;
      Sentry.captureException(enhancedError);
      console.error("Error in getProductAndCreateCheckout:", error);
      dispatch(startSubscriptionCheckoutFailure(error.message));
    }
  };
