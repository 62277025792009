import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

// bootstrap rtl for rtl support page
import "assets/vendor/bootstrap-rtl/bootstrap-rtl.scss";
// plugins styles from node_modules
import "react-perfect-scrollbar/dist/css/styles.css";
import "assets/css/login.css";
// plugins styles downloaded
import "assets/vendor/quill/dist/quill.core.css";
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
// core styles
import "assets/scss/argon-dashboard-pro-react.scss?v1.1.0";
import "assets/css/custom.css";
//import "assets/css/argon-dashboard-pro-react.min.css";
import "assets/scss/global.scss";
import "react-toastify/dist/ReactToastify.css";
// redux
import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import stripeReducer from "./store/reducers/stripe";
import { Provider } from "react-redux";
import { thunk } from "redux-thunk";
import { ToastContainer } from "react-toastify";
import * as Sentry from "@sentry/react";
// App
import App from "./App";

import { hotjar } from "react-hotjar";
if (process.env.REACT_APP_STAGE === "prod") {
  hotjar.initialize(1963107, 6);
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment:
    process.env.REACT_APP_STAGE === "prod" ? "production" : "staging",
});

//To be used when we use middleware
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  stripe: stripeReducer,
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

const loadGoogleMapsScript = (callback) => {
  const existingScript = document.getElementById("googleMaps");
  if (!existingScript) {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
    script.id = "googleMaps";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  } else if (callback) callback();
};

loadGoogleMapsScript(() => {
  const root = createRoot(document.getElementById("root"));
  root.render(
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
      <ToastContainer stacked />
    </Provider>
  );
});
