import React, { useEffect, useState } from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Container,
  Input,
  Row,
  Col,
  CardTitle,
  CardSubtitle,
  FormText,
  Label,
} from "reactstrap";
// redux
import * as actions from "../../../store/actions/index";
import * as helpers from "../../../helpers";
import { connect } from "react-redux";
import Loader from "../../../components/Loader";
// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";
import {
  firebaseAnalytics,
  firebaseAnalyticsEventName,
} from "../../../firebase/firebase";
import { us_states } from "../../../data/us_states";
import Select from "react-select";
import PhoneInput from "components/Inputs/PhoneInput";
import LocationSearchInput from "components/Inputs/LocationSearchInput";
import BeatLoader from "react-spinners/BeatLoader";
import ContentLoader from "react-content-loader";
import { useParams } from "react-router";
import { genders } from "data/genders";
import { logEvent } from "firebase/analytics";
import { notificationError } from "utils/notifications";
import { notificationSuccess } from "utils/notifications";

const CheckoutV2 = ({
  sendCheckoutForm,
  getProduct,
  checkoutSessionId,
  checkoutSessionError,
  product,
  loadSuccess,
  isLoadingSession,
}) => {
  const { id } = useParams();
  const [state, setState] = useState({
    checked: false,
    productsFetched: false,
    quantity: 1,
    email: "",
    first_name: "",
    last_name: "",
    date_of_birth: "",
    gender: "",
    phone: "",
    line1: "",
    line2: "",
    city: "",
    state: "",
    postal_code: "",
  });

  const convertDateToServer = (data) => {
    const year = data.substr(0, 4);
    const month = data.substr(5, 2);
    const day = data.substr(8, 2);
    return month + "/" + day + "/" + year;
  };

  const notify = (type, message) => {
    switch (type) {
      case "success":
        notificationSuccess(message);
        break;
      case "danger":
        notificationError(message);
        break;
      default:
        break;
    }
  };

  const showValueOrDefault = (default_val, value) => {
    return value || default_val || "";
  };

  const ageValidation = (bod) => {
    const dob = new Date(bod).getTime();
    const today = new Date().getTime();
    const age = (today - dob) / (365 * 24 * 60 * 60 * 1000);
    return Math.floor(age) >= 18;
  };

  useEffect(() => {
    if (!loadSuccess) {
      const productUrl = parseUrlParameter(id);
      getProduct(productUrl[0]);
    }
  }, [id, loadSuccess]);

  useEffect(() => {
    if (checkoutSessionId) {
      const price = parseFloat(product.variants?.[0].price);
      logEvent(
        firebaseAnalytics,
        firebaseAnalyticsEventName.ADD_SHIPPING_INFO,
        {
          currency: "USD",
          value: price,
          items: [
            {
              item_id: product.id,
              item_name: product?.title,
              currency: "USD",
              price: price,
              quantity: state.quantity,
            },
          ],
        }
      );
      // Redirect to the checkout URL
      window.location.href = checkoutSessionId;
    }

    if (product) {
      const price = parseFloat(product.variants?.[0].price);
      logEvent(firebaseAnalytics, firebaseAnalyticsEventName.BEGIN_CHECKOUT, {
        currency: "USD",
        value: price,
        items: [
          {
            item_id: product.id,
            item_name: product?.title,
            currency: "USD",
            price: price,
            quantity: 1,
          },
        ],
      });
      setState((prevState) => ({ ...prevState, productsFetched: true }));
    }

    if (checkoutSessionError) {
      notify(
        "danger",
        checkoutSessionError ?? "Error to process your request."
      );
    }
  }, [checkoutSessionId, product, checkoutSessionError]);

  const goToShopifyPay = () => {
    const {
      email,
      first_name,
      last_name,
      date_of_birth: dob,
      gender,
      phone,
      line1,
      line2,
      city,
      state: innerState,
      postal_code,
      quantity,
    } = state;

    if (!email || !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      notify("danger", "Please provide valid email address.");
      return;
    }
    if (!phone || phone.length !== 14) {
      notify("danger", "Please provide valid US phone number with 10 digits.");
      return;
    }
    if (!first_name) {
      notify("danger", "Please provide first name.");
      return;
    }
    if (!last_name) {
      notify("danger", "Please provide last name.");
      return;
    }
    if (!helpers.isDateValid(dob)) {
      notify("danger", "Invalid date of birth. Please use the DD/MM/YYYY format.");
      return;
    }
    if (!ageValidation(dob)) {
      notify(
        "danger",
        "Based on our policy, we only take blood test for whom is over 18 years old."
      );
      return;
    }
    if (!gender) {
      notify("danger", "Please choose gender.");
      return;
    }
    if (!line1) {
      notify("danger", "Please provide shipping address line1.");
      return;
    }
    if (!city) {
      notify("danger", "Please provide shipping address city.");
      return;
    }
    if (!innerState) {
      notify("danger", "Please provide shipping address state.");
      return;
    }
    if (!postal_code || postal_code.length !== 5) {
      notify("danger", "Postal code should be 5 digit.");
      return;
    }

    // at this point, everything looks good then call
    sendCheckoutForm(product.id, quantity, {
      email,
      first_name,
      last_name,
      dob: helpers.formatDate(dob),
      gender,
      phone,
      line1,
      line2,
      city,
      innerState,
      postal_code,
    });
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    const newValue = name === "email" ? value?.toLowerCase?.() ?? value : value;
    setState((prevState) => ({ ...prevState, [name]: newValue }));
  };

  const formatPhone = (value) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue.length;

    if (!state.phone || value.length > state.phone.length) {
      if (cvLength < 4) return currentValue;
      if (cvLength < 7)
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`;
    }
    return value;
  };

  const convertDateToInput = (data) => {
    if (data) {
      const month = data.substr(0, 2);
      const day = data.substr(3, 2);
      const year = data.substr(6, 4);
      return `${year}-${month}-${day}`;
    }
    return "";
  };

  const parseUrlParameter = (url) => {
    return url.split("&");
  };

  const price = product?.variants?.[0].price;

  return (
    <>
      <IndexNavbar />
      <div className="content">
        <section className="py-4 pb-8">
          <Container fluid>
            <Row className="justify-content-center text-center">
              <Col md="6">
                <h3
                  className="display-4"
                  style={{
                    fontFamily: "quicksand",
                    fontWeight: "700",
                    color: "#32325D",
                  }}
                >
                  <i className="fa fa-lock" aria-hidden="true"></i>
                  {" Secure Checkout"}
                </h3>
              </Col>
            </Row>
          </Container>
        </section>
        <section
          className="section section-lg pt-lg-0 mt--7"
          style={{
            fontFamily: "quicksand",
            fontWeight: "700",
            color: "#32325D",
          }}
        >
          <Container>
            <Row className="justify-content-center">
              <Col sm={{ size: 4, order: 2 }}>
                <Card className="shadow border-0">
                  <CardBody
                    className="py-5"
                    style={{
                      fontFamily: "quicksand",
                      fontWeight: "700",
                      color: "#32325D",
                    }}
                  >
                    <h4 className="h3">Your Test Package</h4>
                    {!loadSuccess ? (
                      <ContentLoader
                        speed={2}
                        width="100%"
                        height={230}
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                      >
                        <rect
                          x="0"
                          y="0"
                          rx="5"
                          ry="5"
                          width="100%"
                          height="160"
                        />
                        <rect
                          x="0"
                          y="170"
                          rx="5"
                          ry="5"
                          width="80%"
                          height="20"
                        />
                        <rect
                          x="0"
                          y="200"
                          rx="5"
                          ry="5"
                          width="60%"
                          height="20"
                        />
                      </ContentLoader>
                    ) : (
                      <>
                        <CardImg alt="..." src={product?.image.src} top />
                        <p className="h4">{product.title}</p>
                        <Row className="align-items-center mb-1">
                          <p
                            className="text-primary h2 ml-3"
                            style={{
                              width: "76px",
                            }}
                          >
                            ${(price * state.quantity).toFixed(2)}
                          </p>
                        </Row>
                      </>
                    )}
                    {product?.id === "prod_LIYkmKXSvm0PXN" ? (
                      <>
                        <p className="h4">With this promo: </p>

                        <Row>
                          <p className="text-info h3 ml-3">$1.00</p>
                          <p className="h4 ml-1 text-muted mb-0">
                            Crucial Health Test
                          </p>
                          <p className="h6 text-muted m-0 ml-1">
                            (inc. in price)
                          </p>
                        </Row>
                      </>
                    ) : (
                      <Row>
                        <p className="h5 ml-3">
                          You can apply promotion code at the next step
                        </p>
                      </Row>
                    )}
                    {product?.id === "prod_LIYkmKXSvm0PXN" ? (
                      <>
                        <p className="description mt-3">
                          <span className="btn-inner--icon text-primary">
                            <i className="fas fa-check"></i>
                          </span>
                          <span className="btn-inner--text">
                            {" Purchase & Get A Crucial Health Test For $1"}
                          </span>
                        </p>
                        <p className="description mt-3">
                          <span className="btn-inner--icon text-primary">
                            <i className="fas fa-check"></i>
                          </span>
                          <span className="btn-inner--text">
                            {" 24/7 Friendly Customer Support"}
                          </span>
                        </p>
                        <p className="description mt-3">
                          <span className="btn-inner--icon text-primary">
                            <i className="fas fa-check"></i>
                          </span>
                          <span className="btn-inner--text">
                            {" Easy To Understand Results"}
                          </span>
                        </p>
                        <p className="description mt-3">
                          <span className="btn-inner--icon text-primary">
                            <i className="fas fa-check"></i>
                          </span>
                          <span className="btn-inner--text">
                            {" Automatic Graphical Tracking"}
                          </span>
                        </p>
                        <p className="description mt-3">
                          <span className="btn-inner--icon text-primary">
                            <i className="fas fa-check"></i>
                          </span>
                          <span className="btn-inner--text">
                            {" Secure HIPAA & SOK2 Compliant"}
                          </span>
                        </p>
                      </>
                    ) : (
                      <>
                        <p className="description mt-3">
                          <span className="btn-inner--icon text-primary">
                            <i className="fas fa-check"></i>
                          </span>
                          <span className="btn-inner--text">
                            {" 24/7 Customer Service"}
                          </span>
                        </p>
                        <p className="description mt-3">
                          <span className="btn-inner--icon text-primary">
                            <i className="fas fa-check"></i>
                          </span>
                          <span className="btn-inner--text">
                            {" Free Shipping Both Ways"}
                          </span>
                        </p>
                        <p className="description mt-3">
                          <span className="btn-inner--icon text-primary">
                            <i className="fas fa-check"></i>
                          </span>
                          <span className="btn-inner--text">
                            {" Secured Results & Transactions"}
                          </span>
                        </p>
                        <p className="description mt-3">
                          <span className="btn-inner--icon text-primary">
                            <i className="fas fa-check"></i>
                          </span>
                          <span className="btn-inner--text">
                            {" Track Weight, Blood Pressure & Gluecose Meter"}
                          </span>
                        </p>
                        <p className="description mt-3">
                          <span className="btn-inner--icon text-primary">
                            <i className="fas fa-check"></i>
                          </span>
                          <span className="btn-inner--text">
                            {
                              " Flexible Planning (Change Frequency or Cancel At Anytime)"
                            }
                          </span>
                        </p>
                        <p className="description mt-3">
                          <span className="btn-inner--icon text-primary">
                            <i className="fas fa-check"></i>
                          </span>
                          <span className="btn-inner--text">
                            {" We use Shopify payment processing"}
                          </span>
                        </p>
                      </>
                    )}
                  </CardBody>
                </Card>
                <Card className="shadow border-0 d-none d-md-flex">
                  <CardBody className="py-3">
                    <Col className="col-auto p-0">
                      <a className="avatar avatar-xl rounded-circle">
                        <img
                          alt="..."
                          src={require("assets/img/theme/KatyClose.png")}
                        />
                      </a>
                    </Col>
                    <p className="description mt-2">
                      <p className="mb-0 font-weight-bold">Katy Close</p>
                      <span className="btn-inner--icon">
                        {Array(5)
                          .fill()
                          .map((_, index) => (
                            <i className="fas fa-star text-primary"></i>
                          ))}
                      </span>
                      <p className="description">
                        Lab me allowed me to reduce my risk of heart attack by
                        67% by spotting disease early on.
                      </p>
                    </p>
                    <Col className="col-auto p-0">
                      <a className="avatar avatar-xl rounded-circle">
                        <img
                          alt="..."
                          src={require("assets/img/theme/ShannaDove.png")}
                        />
                      </a>
                    </Col>
                    <p className="description mt-2">
                      <p className="mb-0 font-weight-bold">Shanna Dove</p>
                      <span className="btn-inner--icon">
                        {Array(5)
                          .fill()
                          .map((_, index) => (
                            <i className="fas fa-star text-primary"></i>
                          ))}
                      </span>
                      <p className="description">
                        Simple to perform and easy to understand results. Great
                        way to monitor your health over time without a huge
                        doctors bill.
                      </p>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col sm={{ size: 8, order: 1 }}>
                <Card className="shadow border-0">
                  <CardBody className="py-5 px-5">
                    <Row>
                      <h4 className="h3">
                        Please write the information of the person taking the
                        test.
                      </h4>
                      <h5 className="text-muted">
                        If you are ordering for someone else write their
                        information, not yours or the lab won't be able to
                        process the sample.
                      </h5>
                      <div>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Email address
                              </label>
                              <Input
                                value={state.email || ""}
                                name="email"
                                onChange={onChange}
                                id="input-email"
                                type="text"
                              />
                              <FormText color="muted">
                                Important: the login account is associated with
                                your email address.
                              </FormText>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Phone Number
                              </label>
                              <PhoneInput
                                state={state.phone || ""}
                                name="phone"
                                onChange={onChange}
                                id="input-phone"
                                type="text"
                                placeholder="(xxx) xxx-xxxx"
                              />
                              <FormText color="muted">
                                Please use your mobile number so you receive
                                status, tracking updates and warnings on
                                critical results.
                              </FormText>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                First name
                              </label>
                              <Input
                                id="input-first-name"
                                value={state.first_name || ""}
                                name="first_name"
                                onChange={onChange}
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-last-name"
                              >
                                Last name
                              </label>
                              <Input
                                value={state.last_name || ""}
                                id="input-last-name"
                                name="last_name"
                                onChange={onChange}
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="example-date-input"
                              >
                                Date Of Birth (DD/MM/YYYY)
                              </label>
                              <Input
                                value={state.date_of_birth}
                                name="date_of_birth"
                                id="example-date-input"
                                onChange={onChange}
                                type="date"
                              />
                              <FormText color="muted">
                                Important: all testers must be over 18 years
                                old.
                              </FormText>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-last-name"
                              >
                                Gender
                              </label>
                              <Select
                                className="form-control"
                                value={genders.find(
                                  (option) => option.value === state.gender
                                )}
                                name="gender"
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 0,
                                  colors: {
                                    ...theme.colors,
                                    primary: "#F57F32",
                                  },
                                })}
                                onChange={(event) => {
                                  onChange({
                                    target: {
                                      name: "gender",
                                      value: event.value,
                                    },
                                  });
                                }}
                                styles={{
                                  container: (provided) => ({
                                    ...provided,
                                    padding: 0,
                                    borderRadius: "0.25rem",
                                  }),
                                  control: (provided) => ({
                                    ...provided,
                                    border: 0,
                                    height: "100%",
                                    borderRadius: "0.25rem",
                                  }),
                                }}
                                options={genders}
                              />
                              <FormText color="muted">
                                {/* Add breakline  */}
                                We know that gender can be sensitive.
                                <br />
                                Please select what you were genetically born as.
                              </FormText>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
                <Card className="shadow border-0">
                  <CardBody className="py-5">
                    <h4 className="h3">
                      Where would you like us to send your kit? (US only)
                    </h4>
                    <div>
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              Address Line 1
                            </label>
                            <LocationSearchInput onChange={onChange} />
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              Address Line 2 (optional)
                            </label>
                            <Input
                              value={state.line2 || ""}
                              name="line2"
                              autoComplete="address-line2"
                              onChange={onChange}
                              id="input-address"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-city"
                            >
                              City
                            </label>
                            <Input
                              value={state.city || ""}
                              name="city"
                              onChange={onChange}
                              id="input-city"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-city"
                            >
                              State
                            </label>
                            <Select
                              className="form-control"
                              defaultValue={us_states.find(
                                (option) => option.value === state.state
                              )}
                              value={us_states.find(
                                (option) => option.value === state.state
                              )}
                              name="state"
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary: "#F57F32",
                                },
                              })}
                              onChange={(event) => {
                                onChange({
                                  target: {
                                    name: "state",
                                    value: event.value,
                                  },
                                });
                              }}
                              styles={{
                                container: (provided) => ({
                                  ...provided,
                                  padding: 0,
                                  borderRadius: "0.25rem",
                                }),
                                control: (provided) => ({
                                  ...provided,
                                  border: 0,
                                  height: "100%",
                                  borderRadius: "0.25rem",
                                }),
                              }}
                              options={us_states}
                            />
                            <FormText color="muted">
                              New York State does not allow at home blood test.
                            </FormText>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Postal code
                            </label>
                            <Input
                              id="input-postal-code"
                              value={state.postal_code || ""}
                              name="postal_code"
                              onChange={onChange}
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <Row className="justify-content-center">
                      <Col
                        className="text-center px-lg-5 px-4 py-3 text-md-start text-center"
                        lg="12"
                      >
                        <Label check className="text-muted">
                          <Input
                            type="checkbox"
                            id="checkbox2"
                            onChange={(e) =>
                              setState((prevState) => ({
                                ...prevState,
                                checked: e.target.checked,
                              }))
                            }
                          />
                          I confirm that I have read, consent and agree to{" "}
                          <a
                            href="https://labme.ai/policies/terms-of-service"
                            target="_blank"
                          >
                            LabMe's User Agreement
                          </a>
                          {", "}
                          <a
                            href="https://labme.ai/pages/hipaa-privacy-authorization"
                            target="_blank"
                          >
                            HIPAA Authorization
                          </a>{" "}
                          and{" "}
                          <a
                            href="https://labme.ai/policies/privacy-policy"
                            target="_blank"
                          >
                            Privacy Policy
                          </a>{" "}
                        </Label>
                      </Col>
                    </Row>
                    <Row className="justify-content-center">
                      <Col className="text-center" lg="12">
                        <Button
                          color="primary"
                          type="button"
                          style={{
                            background:
                              "linear-gradient(to right, #ef4458, #F57F32)",
                            color: "#FFFFFF",
                            "&:hover": {
                              background:
                                "linear-gradient(to right, #ef4458, #F57F32)",
                              opacity: 0.9,
                            },
                          }}
                          disabled={
                            !state.checked ||
                            isLoadingSession ||
                            checkoutSessionId
                          }
                          onClick={goToShopifyPay}
                        >
                          {isLoadingSession ? (
                            <BeatLoader
                              size={15}
                              color={"#FFFFFF"}
                              loading={true}
                            />
                          ) : checkoutSessionId ? (
                            "Redirecting..."
                          ) : (
                            "Go To Secured Payment"
                          )}
                        </Button>
                      </Col>
                    </Row>
                    <Row className="justify-content-center mt-3">
                      <p className="h4 text-muted">
                        <span className="btn-inner--icon">
                          <i
                            className="fas fa-lock"
                            style={{
                              color: "#ef4458",
                            }}
                          ></i>
                        </span>
                        <span className="btn-inner--text">
                          {" Secure SSL Encrypted Payment"}
                        </span>
                      </p>
                    </Row>
                    <Row className="justify-content-center px-4">
                      <span className="h6 text-muted text-md-start text-center">
                        * By clicking on the payment button, you agree to our{" "}
                        <a
                          href="https://labme.ai/policies/terms-of-service"
                          target="_blank"
                        >
                          Terms of Service
                        </a>{" "}
                        and{" "}
                        <a
                          href="https://labme.ai/policies/privacy-policy"
                          target="_blank"
                        >
                          Privacy Policy
                        </a>
                        .
                      </span>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <AuthFooter />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isCheckoutSessionLoading: state.stripe.isLoading,
    checkoutSessionError: state.stripe.loadError,
    checkoutSessionId: state.stripe.checkout_session_id,
    product: state.stripe.products,
    loadSuccess: state.stripe.loadSuccess,
    isLoadingSession: state.stripe.isLoadingSession,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendCheckoutForm: (id, quantity, user_info) =>
      dispatch(actions.generateCheckout(id, quantity, user_info)),
    getProduct: (id) => dispatch(actions.getProduct(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutV2);
