import { toast } from "react-toastify";
const toast_config = {
  position: "top-center",
  pauseOnFocusLoss: false,
  pauseOnHover: false,
  hideProgressBar: true,
  draggable: false,
  autoClose: 2500,
  closeButton: false,
};
export function notificationError(content, customConfig) {
  return toast.error(content, {
    ...toast_config,
    ...customConfig,
  });
}
export function notificationSuccess(content, customConfig) {
  return toast.success(content, {
    ...toast_config,
    ...customConfig,
  });
}
export function notificationWarning(content, customConfig) {
  return toast.warning(content, {
    ...toast_config,
    ...customConfig,
  });
}
export function notificationInfo(content, customConfig) {
  return toast.info(content, {
    ...toast_config,
    ...customConfig,
  });
}
